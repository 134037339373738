<template>
  <layout width="428">
    <template slot="header">
      {{title || `Edit property: ${ currentItem.humanReadable }`}}
    </template>
    <template>
      <template v-if="!currentItem.valueSet && !currentItem.valueRange">
        <v-select
          v-if="isBoolean(currentItem.typeId)"
          v-model="innerValue"
          label="Value"
          item-value="id"
          item-text="name"
          :items="[
            { id: true, name: 'true' },
            { id: false, name: 'false' },
            { id: null, name: 'null' }
          ]"
          class="pb-4"
        />
        <code-editor
          v-else-if="isJson(currentItem.typeId)"
          v-model="innerValue"
          :code="JSON.stringify(currentItem.value)"
          @is-error="isCodeError = $event"
        />

        <template v-else>
          <v-text-field
            v-if="isNumber(currentItem.typeId)"
            v-model.number="innerValue"
            type="number"
            label="Value"
            clearable
            :placeholder="currentItem.property"
          />
          <v-text-field
            v-else
            v-model="innerValue"
            label="Value"
            clearable
            :placeholder="currentItem.property"
          />
        </template>
      </template>
      <template v-else>
        <v-select
          v-if="
            currentItem.valueSet && currentItem.valueSet.component === 'select'
          "
          v-model="innerValue"
          item-value="key"
          class="pb-4"
          item-text="title"
          :items="currentItem.valueSet.list"
        />
        <v-text-field
          v-else-if="currentItem.valueSet"
          v-model="innerValue"
          class="pb-4"
        />
        <v-text-field
          v-if="currentItem.valueRange"
          v-model.number="innerValue"
          class="pb-4"
          :max="currentItem.valueRange.max"
          :min="currentItem.valueRange.min"
          type="number"
        />
      </template>
    </template>
    <template slot="footer">
      <v-spacer />
      <v-btn text @click="close">Cancel</v-btn>
      <v-btn color="primary" text :loading="loading" @click="saveHandler">
        save
      </v-btn>
    </template>
  </layout>
</template>

<script>
import Layout from './PopupLayoutDefault';
import { defineComponent, ref, onMounted } from '@vue/composition-api';
import CodeEditor from '@/components/_base/CodeEditor';

import { isJson, isBoolean, isNumber } from '@/utils/getCurrentType';

export default defineComponent({
  name: 'EditProperty',
  components: {
    CodeEditor,
    Layout
  },
  props: {
    currentItem: {
      type: Object,
      required: true
    },
    onConfirm: {
      type: Function,
      default: () => {}
    },
    title: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const innerValue = ref(null);
    const isCodeError = ref(false);
    const loading = ref(false);

    const close = () => {
      emit('close');
    };

    const prepareBeforeSend = value => {
      if (isJson(props.currentItem.typeId)) {
        if (typeof value === 'string') {
          return JSON.parse(value);
        } else {
          return value;
        }
      } else if (isNumber(props.currentItem.typeId)) {
        return +value;
      }
      return value;
    };

    const saveHandler = async () => {
      try {
        loading.value = true;
        await props.onConfirm(prepareBeforeSend(innerValue.value));
        loading.value = false;
        emit('close');
      } finally {
        loading.value = false;
      }
    };

    onMounted(() => {
      if (isJson(props.currentItem.typeId)) {
        innerValue.value = JSON.stringify(props.currentItem.value);
      } else {
        innerValue.value = props.currentItem.value;
      }
    });

    return {
      isCodeError,
      innerValue,
      saveHandler,
      close,
      isJson,
      isBoolean,
      isNumber,
      loading
    };
  }
});
</script>
